import type { AppProps } from 'next/app'
import React, { useEffect, useState } from 'react'
import LandingPageLayout from 'layout/index'
import NextNProgress from 'nextjs-progressbar'
import 'styles/globals.scss'
import { SSRProvider } from 'react-bootstrap'
import { GlobalContext } from 'context/globalContext'
import { SessionProvider, getSession } from 'next-auth/react'
import { useRouter } from 'next/router'

const MainApp = ({ Component, pageProps }: AppProps) => {
  const router = useRouter()
  const [hasWindow, setHasWindow] = useState(false)

  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false)

  const [userData, setUserData] = useState(
    JSON.parse(
      (typeof window !== 'undefined' &&
        window.localStorage.getItem('userData')) ||
        '{}'
    )
  )

  const [signIn, setSignIn] = useState(null)

  const [appToken, setAppToken] = useState<string | null>(null)

  const setUserInfo = (data: { loginActivity: string | any[] }) => {
    setUserData(data)
    // const activityId = data.loginActivity.slice(-1)
    // window.localStorage.setItem('activityId', activityId[0]._id)
    // window.localStorage.setItem('userData', JSON.stringify(data))
  }

  const setUserToken = (data: string) => {
    window.localStorage.setItem('token', data)
  }
  const getSessionAsync = async () => {
    const session: any = await getSession()
    if (session) {
      setIsLoggedIn(true)
      setAppToken(session.user.token)
    } else {
      setIsLoggedIn(false)
      setAppToken(null)
    }
  }
  useEffect(() => {
    if (typeof window !== 'undefined') {
      setHasWindow(true)
    }
    getSessionAsync()
  }, [router])

  return (
    <>
      {hasWindow && (
        <SessionProvider>
          <GlobalContext.Provider
            value={{
              isLoggedIn,
              setIsLoggedIn,
              setUserInfo,
              userData,
              appToken,
              setUserToken,
              signIn,
              setSignIn,
            }}
          >
            <SSRProvider>
              <LandingPageLayout>
                <NextNProgress
                  height={5}
                  color="#ff145f"
                  options={{
                    showSpinner: false,
                  }}
                />
                <Component {...pageProps} />
              </LandingPageLayout>
            </SSRProvider>
          </GlobalContext.Provider>
        </SessionProvider>
      )}
    </>
  )
}

export default MainApp
