import { API_URL, COMMON_BASE_URL, USER_BASE_URL } from 'services/config'
import fetchApi from 'utils/fetchApi'
import { getSession } from 'next-auth/react'
import { addUserDetails } from 'utils/getBrowserInfo'

// Auth API's Start //

export const firstStepRegister = async (data) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/firstStepRegister`, 'POST', data)
}

export const fetchUserDetail = async (data) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/fetchUserDetail`, 'GET', data)
}

export const registerUser = async (data) => {
  const newUserDetails = await addUserDetails(data)
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/registerUser`,
    'POST',
    newUserDetails
  )
}

export const login = async (data: { emailId: string; password: string }) => {
  const loginDetails = await addUserDetails(data)
  return fetchApi(`${API_URL}${USER_BASE_URL}/login`, 'POST', loginDetails)
}

export const verificationCode = async (id: string | null, data: any) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/verifySecurityCode/${id}`,
    'POST',
    data
  )
}

export const resendVerificationCode = async (id, data) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/resendCode/${id}`, 'POST', data)
}

export const forgotPasswordAsync = async (data: { emailId: string }) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/forgotPassword`, 'POST', data)
}

export const setNewPasswordAsync = async (data, redisId) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/setNewPassword/${redisId}`,
    'POST',
    data
  )
}

export const logout = async () => {
  const Session: any = await getSession()
  return fetchApi(`${API_URL}${USER_BASE_URL}/logout/${Session.user.id}`, 'PUT')
}

// Auth API's End //

// Home Page API's Start //

export const getCompanies = (limit: number, skip: number, data) => {
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/getCompanies?limit=${limit}&skip=${skip}`,
    'POST',
    data
  )
}

export const getFilterValuesAsync = () => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getFilterValues`, 'GET')
}

export const getCountForHomepageAsync = () => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getCountForHomepage`, 'GET')
}

// Home Page API's End //

// Companies API's Start //

export const getCompanyDetailAsync = async (id) => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getCompanyDetail/${id}`, 'GET')
}

export const fetchCompanyJobsAsync = async (
  id,
  limit: number,
  skip: number
) => {
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/fetchCompanyJobs/${id}?limit=${limit}&page=${skip}`,
    'GET'
  )
}

export const submitApplicantDetailsAsync = async (
  jobPostId: string,
  details: any
) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/applyJobPost/${Session.user.id}/${jobPostId}`,
    'POST',
    details
  )
}

export const fetchJobDetailAsync = async (id) => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/fetchJobDetail/${id}`, 'GET')
}

// Companies Page API's End //

// Dashboard API's Start //

export const getJobListForDashboardAsync = async (
  limit: number,
  page: number,
  searchBody
) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/getJobListForDashboard/${Session.user.id}?limit=${limit}&page=${page}`,
    'POST',
    searchBody
  )
}

export const changeJobStatusAsync = async (
  jobId: string,
  detail: { status: string }
) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/changeJobStatus/${jobId}`,
    'POST',
    detail
  )
}

// Dashboard API's End //

// DashboardApplicants API's Start //
export const getApplicantsForDashboardAsync = async (
  jobId: string,
  limit: number,
  page: any,
  searchBody: { showRejected: boolean; sortBy: string; sortType: string }
) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/getApplicantsForDashboard/${Session.user.id}/${jobId}?limit=${limit}&page=${page}`,
    'POST',
    searchBody
  )
}

export const changeApplicationStatusAsync = async (
  jobId: string,
  applicantId: string,
  detail
) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/changeApplicantionStatus/${jobId}/${applicantId}`,
    'PUT',
    detail
  )
}

export const commentToApplicantAsync = async (
  jobId: string,
  applicantId: string,
  details: any
) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/commentToApplicant/${Session.user.id}/${jobId}/${applicantId}`,
    'POST',
    details
  )
}

export const getApplicantCommentsAsync = async (
  jobId: string,
  applicantId: string
) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/getComments/${jobId}/${applicantId}`,
    'GET'
  )
}

// DashboardApplicants API's End //

// Common API's Start //
export const subscribeAsync = (data: { email: string }) => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/subscribe`, 'POST', data)
}

export const getLocationsAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getLocations`, 'GET')
}

export const getContractTypeAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getContractType`, 'GET')
}

export const getLanguagesAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getLanguages`, 'GET')
}

export const changePasswordAsync = async (details: {
  currentPassword: string
  newPassword: string
}) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/changePasswordRequest/${Session.user.id}`,
    'POST',
    details
  )
}

export const followCompanyAsync = async (companyId, request) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/followCompany/${Session.user.id}/${companyId}?request=${request}`,
    'POST'
  )
}

export const likeJobAsync = async (jobId, request) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/likeJob/${Session.user.id}/${jobId}?request=${request}`,
    'POST'
  )
}

export const uploadImageAsync = async (file) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/fileUpload/uploadImageOnS3`,
    'POST',
    file
  )
}

export const TwoFactorAuthenticationAsync = async (data) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/twoFactorAuthenticationToggle/${Session.user.id}`,
    'POST',
    data
  )
}
export const fetchTwoFactorMode = async () => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/fetchTwofactor/${Session.user.id}`,
    'GET'
  )
}
// Common API's End //

// Crypto compnies Page API's Start //

export const fetchCompaniesAsync = async (
  limit: number,
  skip: number,
  data
) => {
  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/fetchCompanies?limit=${limit}&skip=${skip}`,
    'POST',
    data
  )
}
// Crypto compnies Page API's End //

// Create Company Page API's Start //
export const checkCompanyCreated = (id) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/checkCompanyCreated/${id}`, 'GET')
}

export const getHiringManagerDetailsAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getHiringManager`, 'GET')
}
export const getIndustryTypeDetailsAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getIndustryType`, 'GET')
}
export const getCompanyTypeDetailsAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getCompanyType`, 'GET')
}
export const getCompanySizeAsync = () => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/getCompanySize`, 'GET')
}
export const registerCompanyAsync = async (data) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/registerCompany/${Session.user.id}`,
    'POST',
    data
  )
}

export const getCompanyDetailForEdit = async (id) => {
  return fetchApi(`${API_URL}${USER_BASE_URL}/editCompanyProfile/${id}`, 'GET')
}
export const updateCompanyProfile = async (companyId, data) => {
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/updateCompanyProfile/${companyId}`,
    'PUT',
    data
  )
}
// Create Company Page API's End //

// Create Job Page API's Start //

export const getCompnyDetailsAsync = async () => {
  const Session: any = await getSession()

  return fetchApi(
    `${API_URL}${USER_BASE_URL}/getCompanyDetails/${Session.user.id}`,
    'GET'
  )
}

export const submitJobPostAsync = async (companyId, data) => {
  const Session: any = await getSession()
  return fetchApi(
    `${API_URL}${USER_BASE_URL}/submitJobPost/${Session.user.id}/${companyId}`,
    'POST',
    data
  )
}

// Create Job Page API's End //

// Create onBoarding API's Start //
export const getJobRole = async () => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getAllJobRole`, 'GET')
}

export const getSubRole = async (jobRoleId) => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getSubRole/${jobRoleId}`, 'GET')
}

export const getAllLanguagesAsync = async () => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getAllLanguages`, 'GET')
}

export const getAllLocationsAsync = async () => {
  return fetchApi(`${API_URL}${COMMON_BASE_URL}/getAllLocations`, 'GET')
}
// Create onBoarding API's End //

export const paymentGateway = async () => {
  const Session: any = await getSession()

  return fetchApi(
    `${API_URL}${COMMON_BASE_URL}/checkout/create-checkout-session?uid=${Session.user.id}`,
    'POST'
  )
}

export const paymentSuccess = async (id) => {
  const Session: any = await getSession()

  return fetchApi(`${API_URL}${COMMON_BASE_URL}/checkout/webhook`, 'POST')
}
