import { getSession } from 'next-auth/react'
import axios, { Method } from 'axios'

const fetchApi = async (
  url: string,
  method: Method | undefined,
  data = {},
  headers = {}
) => {
  const session: any = await getSession()
  let headerData = headers
  if (session) {
    headerData = {
      Authorization: `Bearer ${session.user.token}`,
    }
  }
  const result = await axios(`${url}`, {
    method,
    headers: headerData,
    data,
  })
  const response = await result.data

  // if (response.responseCode !== 200) {

  //     throw new Error(JSON.stringify(response))
  //   }
  return response
}

export default fetchApi
