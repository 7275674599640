import React, { FC } from 'react'
import Link from 'next/link'

const Footer: FC = () => {
  return (
    <section>
      <footer id="home_footer1">
        <div className="container">
          {/* <div className="row align-items-end">
            <div className="col-lg-4 col-12 text-center text-right text-lg-start pb-lg-2 pb-sm-3">
              <a href="/">
                <img
                  src="/assets/images/logo.png"
                  alt="logo"
                  className="sw-width-2xs"
                />
              </a>
              <small className="fs-14px mt-lg-3 text-white fw-400 sw-padding-top-none sw-padding-bottom-3xs sw-display-block noto_font_400 nato opacity-40">
                © 2022 0xCareer.com. All rights reserved.
              </small>
            </div>
            <div className="col-lg-5 col-12 my-4 my-lg-0">
              <div className="nav justify-content-center justify-content-lg-start">
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Hire Talent</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Job Openings</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Companies</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Web3 Sectors</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Pricing</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Contact</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-12 pb-2 text-lg-end text-center foot-socicon">
              <p className="text-white fs-16px fw-400 text-uppercase opacity-60 lat_space mb-2">
                Connect with us:
              </p>
              <span className="me-2">
                <a href="#" id="fb">
                  <img
                    src="/assets/images/fb_round.png"
                    alt="logo"
                    className="img-fluid "
                  />
                  <div className="overlay_img">
                    {' '}
                    <img
                      src="/assets/images/facebook_hover.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </a>
              </span>
              <span className="me-2">
                <a href="#" id="twitter">
                  <img
                    src="/assets/images/twitter_round.png"
                    alt="logo"
                    className="img-fluid "
                  />
                  <div className="overlay_img">
                    {' '}
                    <img
                      src="/assets/images/twitter_hover.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </a>
              </span>
              <span className="me-2">
                <a href="#" id="linkdien">
                  <img
                    src="/assets/images/linkdien-round.png"
                    alt="logo"
                    className="img-fluid "
                  />
                  <div className="overlay_img">
                    {' '}
                    <img
                      src="/assets/images/linkdien_hover.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </a>
              </span>
              <span className="me-2">
                <a href="#" id="aps">
                  <img
                    src="/assets/images/aps.png"
                    alt="logo"
                    className="img-fluid "
                  />
                  <div className="overlay_img">
                    {' '}
                    <img
                      src="/assets/images/aps_hover.png"
                      alt="logo"
                      className="img-fluid "
                    />
                  </div>
                </a>
              </span>
            </div>
          </div> */}
          <div className="align-items-end d-flex flex-row">
            <div className="pe-3 foot-logo">
              <a href="/">
                <img
                  src="/assets/images/logo.png"
                  alt="logo"
                  className="sw-width-2xs"
                />
              </a>
              <small className="fs-14px mt-lg-3 text-white fw-400 sw-padding-top-none sw-padding-bottom-3xs sw-display-block noto_font_400 nato opacity-40">
                {`© ${new Date().getFullYear()} 0xCareer.com. All rights reserved.`}
              </small>
            </div>
            <div className=" flex-grow-1 ps-lg-5">
              <div className="nav justify-content-center justify-content-lg-start">
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Hire Talent</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Job Openings</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/cryptoCompanies" passHref>
                    <a className="nav-link">Companies</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Web3 Sectors</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/pricing" passHref>
                    <a className="nav-link">Pricing</a>
                  </Link>
                </div>
                <div className="w-33px">
                  <Link href="/" passHref>
                    <a className="nav-link">Contact</a>
                  </Link>
                </div>
              </div>
            </div>
            <div className=" pb-2 text-lg-end text-center foot-socicon">
              <div>
                <p className="text-white fs-16px fw-400 text-uppercase opacity-60 lat_space mb-2">
                  Connect with us:
                </p>
              </div>
              <div>
                <span className="me-2">
                  <a
                    href="https://t.me/zeroxcareer"
                    target="_blank"
                    rel="noreferrer"
                    id="fb"
                  >
                    <img
                      src="/assets/images/telegram.png"
                      alt="logo"
                      className="img-fluid "
                    />
                    <div className="overlay_img">
                      {' '}
                      <img
                        src="/assets/images/telegramhover.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                  </a>
                </span>
                <span className="me-2">
                  <a
                    href="https://twitter.com/0xCareer"
                    target="_blank"
                    id="twitter"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/images/twitter_round.png"
                      alt="logo"
                      className="img-fluid "
                    />
                    <div className="overlay_img">
                      {' '}
                      <img
                        src="/assets/images/twitter_hover.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                  </a>
                </span>
                <span className="me-2">
                  <a href="#" id="linkdien">
                    <img
                      src="/assets/images/linkdien-round.png"
                      alt="logo"
                      className="img-fluid "
                    />
                    <div className="overlay_img">
                      {' '}
                      <img
                        src="/assets/images/linkdien_hover.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                  </a>
                </span>
                <span className="me-2">
                  <a
                    href="https://discord.gg/eDrYAmwgKG"
                    target="_blank"
                    id="aps"
                    rel="noreferrer"
                  >
                    <img
                      src="/assets/images/aps.png"
                      alt="logo"
                      className="img-fluid "
                    />
                    <div className="overlay_img">
                      {' '}
                      <img
                        src="/assets/images/aps_hover.png"
                        alt="logo"
                        className="img-fluid "
                      />
                    </div>
                  </a>
                </span>
              </div>
            </div>
          </div>
        </div>
      </footer>
    </section>
  )
}

export default Footer
