import React, { FC, useEffect, useState } from 'react'
import { Button, Container, Nav, Navbar } from 'react-bootstrap'
import Link from 'next/link'
import { GlobalContext } from 'context/globalContext'
import { Router, useRouter } from 'next/router'
import toast from 'helpers/toast'
import { ToastContainer } from 'react-toastify'
import DashboardIcon from '@mui/icons-material/Dashboard'
import KeyIcon from '@mui/icons-material/Key'
import SecurityIcon from '@mui/icons-material/Security';
import CircularProgress from '@mui/material/CircularProgress'
import Backdrop from '@mui/material/Backdrop'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import ListItemIcon from '@mui/material/ListItemIcon'
import Divider from '@mui/material/Divider'
import IconButton from '@mui/material/IconButton'
import Tooltip from '@mui/material/Tooltip'
import Logout from '@mui/icons-material/Logout'
import { useSession, signOut } from 'next-auth/react'
import { checkCompanyCreated, logout } from 'services/webservice/api'

const Header: FC = () => {
  const { data: session }: any = useSession()

  const { isLoggedIn, userData, setIsLoggedIn } =
    React.useContext(GlobalContext)
  const router = useRouter()

  const showHeader =
    router.pathname === '/CreateCompany' || router.pathname === '/addjob'
      ? true
      : false
  const [show, setShow] = useState(false)
  const [isActive, setActive] = useState<boolean>(false)
  const [eventKey, seteventKey] = useState<string>('1')
  const [loading, setLoading] = useState(false)

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  const location = useRouter()

  const onClickToLogout = async (e) => {
    e.preventDefault()
    signOut()
    // const res = await logout()
    // if (res.responseCode === 200) {
    //   signOut()
    //     .then(() => toast.success(res.responseMessage))
    //     .catch((err) => toast.error(err))
    // } else {
    //   toast.error(res.responseMessage)
    // }
  }
  const handleOpenChangePassword = () => {
    router.push('/changePassword')
  }
  const handleOpenTwoFactorSetting = () => {
    router.push('/two-factor-setting')
  }
  const handleOpenDashboard = () => {
    router.push('/dashboard')
  }
  // const handleSelect = (key) => seteventKey(key)

  const onClickToLogin = () => {
    router.push('/login')
  }
  const handleRedirectPostJob = async () => {
    if (isLoggedIn) {
      setLoading(true)
      const checkIsCompanyCreate = await checkCompanyCreated(session.user.id)
      if (checkIsCompanyCreate.responseCode === 200) {
        setLoading(false)
        router.push('/CreateCompany')
      } else {
        setLoading(false)
        router.push('/addjob')
      }
    } else {
      setLoading(false)
      router.push('/login')
    }
  }
  useEffect(() => {
    if (router.pathname === '/jobs') {
      seteventKey('2')
    } else if (router.pathname === '/') {
      seteventKey('1')
    } else if (router.pathname === '/pricing') {
      seteventKey('3')
    } else if (router.pathname === '/cryptoCompanies') {
      seteventKey('4')
    } else {
      seteventKey('')
    }
  }, [router.pathname])

  return (
    <>
      <ToastContainer />

      <Backdrop
        sx={{
          color: '#fff',
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Navbar expand="lg" className="py-3 mb-2">
        <Container>
          <Navbar.Collapse id="basic-navbar-nav" className="desktop-menu">
            {router.pathname !== '/submitOnboardingPage' ? (
              <Nav
                className="align-items-center nav_text"
                // onSelect={handleSelect}
                activeKey={eventKey}
              >
                <Link href="/" passHref>
                  <Nav.Link
                    className="text-white fs-18px fw-700 sw-letter-spacing-wider me-4"
                    eventKey="1"
                  >
                    HOME
                  </Nav.Link>
                </Link>
                <Link href="/jobs" passHref>
                  <Nav.Link
                    className="text-white fs-18px fw-700 sw-letter-spacing-wider me-4"
                    eventKey="2"
                  >
                    ALL&nbsp;JOBS
                  </Nav.Link>
                </Link>
                <Link href="/pricing" passHref>
                  <Nav.Link
                    className="text-white fs-18px fw-700 sw-letter-spacing-wider me-4"
                    eventKey="3"
                  >
                    PRICING
                  </Nav.Link>
                </Link>
              </Nav>
            ) : null}
          </Navbar.Collapse>
          <Navbar.Brand href="#home">
            <div style={{ position: 'relative' }}>
              <Link href="/">
                <a>
                  <img
                    src="/assets/images/headlogos.png"
                    alt="logo"
                    className="img-fluid sw-width-2xs"
                  />
                </a>
              </Link>
            </div>
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav" className="desktop-menu">
            {router.pathname !== '/submitOnboardingPage' ? (
              <Nav
                className="ms-auto align-items-center"
                onClick={handleRedirectPostJob}
              >
                {/* <Link href={`${isLoggedIn ? '/addjob' : '/login'}`}> */}
                <Button
                  className="fs-20px fw-600  sw-padding-left-xs sw-padding-right-xs sw-border-width-s post_job_btn"
                  style={{ height: '48px' }}
                >
                  Post&nbsp;job
                </Button>
                {/* </Link> */}
              </Nav>
            ) : null}

            {isLoggedIn ? (
              <div className="">
                <Tooltip title="Account settings">
                  <IconButton
                    onClick={handleClick}
                    size="small"
                    sx={{ ml: 2 }}
                    aria-controls={open ? 'account-menu' : undefined}
                    aria-haspopup="true"
                    aria-expanded={open ? 'true' : undefined}
                  >
                    <img
                      src={
                        session?.user?.profilePicture === ""
                          ? '/assets/images/user.png'
                          : session?.user?.profilePicture
                      }
                      alt="profile image"
                      className="user_img"
                    />
                  </IconButton>
                </Tooltip>
                <Menu
                  anchorEl={anchorEl}
                  id="account-menu"
                  open={open}
                  onClose={handleClose}
                  onClick={handleClose}
                  PaperProps={{
                    elevation: 0,
                    sx: {
                      overflow: 'visible',
                      filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                      mt: 1.5,
                      '& .MuiAvatar-root': {
                        width: 32,
                        height: 32,
                        ml: -0.5,
                        mr: 1,
                      },
                      '&:before': {
                        content: '""',
                        display: 'block',
                        position: 'absolute',
                        top: 0,
                        right: 14,
                        width: 10,
                        height: 10,
                        bgcolor: 'background.paper',
                        transform: 'translateY(-50%) rotate(45deg)',
                        zIndex: 0,
                      },
                    },
                  }}
                  transformOrigin={{ horizontal: 'right', vertical: 'top' }}
                  anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
                >
                  <MenuItem onClick={handleOpenDashboard}>
                    <DashboardIcon sx={{ mr: 2, opacity: 0.5 }} /> Dashboard
                  </MenuItem>
                  <Divider sx={{ opacity: 0.9 }} />
                  {session?.user?.provider !== 'google' && (
                    <MenuItem onClick={handleOpenChangePassword}>
                      <ListItemIcon>
                        <KeyIcon fontSize="small" />
                      </ListItemIcon>
                      Change Password
                    </MenuItem>
                  )}
                  {session?.user?.provider !== 'google' && (
                    <MenuItem onClick={handleOpenTwoFactorSetting}>
                      <ListItemIcon>
                        <SecurityIcon fontSize="small" />
                      </ListItemIcon>
                      Two-factor Setting
                    </MenuItem>
                  )}

                  <MenuItem onClick={(e) => onClickToLogout(e)}>
                    <ListItemIcon>
                      <Logout fontSize="small" />
                    </ListItemIcon>
                    Logout
                  </MenuItem>
                </Menu>
              </div>
            ) : router.pathname !== '/submitOnboardingPage' ? (
              <Button className="login_button" onClick={onClickToLogin}>
                LOG IN
              </Button>
            ) : null}

            {/* {isLoggedIn ? (
              <Link href="/changePassword" passHref>
                <Button variant="light" className="change_password">
                  CHANGE PASSWORD
                </Button>
              </Link>
            ) : null}

            {isLoggedIn ? (
              <Link href="" passHref>
                <Button
                  variant="danger"
                  className="logout"
                  onClick={(e) => onClickToLogout(e)}
                >
                  LOGOUT
                </Button>
              </Link>
            ) : null} */}
          </Navbar.Collapse>

          <div className="d-lg-none d-sm-block">
            <div className="mob_toggle-button">
              <button
                className="add bg-transparent border-0 "
                onClick={() => setShow(true)}
              >
                {' '}
                <span className="navbar-toggler-icon" />
              </button>
            </div>
            <div className="mob_menu d-lg-none d-sm-block ">
              <div
                className={`nav ${
                  show ? 'show_sidebar' : ''
                } mob_menu sidenav `}
              >
                <button
                  className="clsoe_btn_icon"
                  onClick={() => setShow(false)}
                >
                  <img
                    src="/assets/images/close.png"
                    alt="logo"
                    className="close_sidenav img-fluid sw-width-2xs"
                  />
                </button>
                <Navbar.Brand href="#home" className="mb-4">
                  <div style={{ position: 'relative' }}>
                    <img
                      src="/assets/images/logo.png"
                      alt="logo"
                      className="img-fluid  sw-width-2xs "
                    />
                  </div>
                  <div className="user_img mt-3">
                    <Link href="/">
                      <a>
                        <img
                          src="/assets/images/user.png"
                          alt="logo"
                          className="img-fluid sw-width-xs ms-2"
                        />
                      </a>
                    </Link>
                  </div>
                </Navbar.Brand>
                <Nav.Link
                  href="/"
                  className="text-white fs-18px fw-700 sw-letter-spacing-wider padd_right_41"
                >
                  HOME
                </Nav.Link>
                <Nav.Link
                  href="#"
                  className="text-white fs-18px fw-700 sw-letter-spacing-wider padd_right_41"
                >
                  {' '}
                  ALL&nbsp;JOBS
                </Nav.Link>
                <Nav.Link
                  href="#"
                  className="text-white fs-18px fw-700 sw-letter-spacing-wider padd_right_41"
                >
                  {' '}
                  PRICING{' '}
                </Nav.Link>
                <Link href="/addjob">
                  <Button className="fs-18px fw-600 sw-padding-left-xs sw-padding-right-xs sw-border-width-s post_job_btn">
                    Post&nbsp;job
                  </Button>
                </Link>
              </div>
            </div>
          </div>
        </Container>
      </Navbar>
      <section className="tab_section topbar">
        <div className="container parent_Tab">
          <div className="full_width">
            <ul className="mbs-3 nav nav-tabs" role="tablist">
              {showHeader ? null : (
                <>
                  {
                    isLoggedIn && router.pathname !== '/dashboard' ? (
                      <>
                        <li className="nav-item" role="presentation">
                          <Nav activeKey={eventKey}>
                            <Link href="/cryptoCompanies" passHref>
                              <Nav.Link eventKey="4">
                                <span>
                                  <img src="/assets/images/compny.svg" />
                                  COMPANIES{' '}
                                </span>
                              </Nav.Link>
                            </Link>
                          </Nav>
                        </li>
                        <li className="nav-item" role="presentation">
                          <Nav
                            // onSelect={handleSelect}
                            activeKey={eventKey}
                          >
                            <Link href="/jobs" passHref>
                              <Nav.Link eventKey="2">
                                <span>
                                  <img src="/assets/images/jobs.svg" />
                                  JOBS{' '}
                                </span>
                              </Nav.Link>
                            </Link>
                          </Nav>
                        </li>
                        {/* <li className="nav-item" role="presentation">
                          <Link href="/ApplicationPage">
                            <a
                              id="tab1-tab-APPLICATIONS"
                              className={
                                location.pathname === '/ApplicationPage'
                                  ? 'nav-link active'
                                  : 'nav-link'
                              }
                              aria-selected="true"
                            >
                              <span>
                                <img src="/assets/images/apps.svg" />
                                APPLICATIONS{' '}
                              </span>
                            </a>
                          </Link>
                        </li> */}
                      </>
                    ) : null
                    // (
                    //   <>
                    //     <li className="nav-item" role="presentation">
                    //       <Nav activeKey={eventKey}>
                    //         <Link href="/dashboard" passHref>
                    //           <Nav.Link eventKey="5">
                    //             <span>
                    //               <img src="/assets/images/dashboard-icon.svg" />
                    //               DASHBOARD{' '}
                    //             </span>
                    //           </Nav.Link>
                    //         </Link>
                    //       </Nav>
                    //     </li>
                    //     <li className="nav-item" role="presentation">
                    //       <Nav activeKey={eventKey}>
                    //         <Link href="/" passHref>
                    //           <Nav.Link eventKey="6">
                    //             <span>
                    //               <img src="/assets/images/billing-icon.svg" />
                    //               BILLING{' '}
                    //             </span>
                    //           </Nav.Link>
                    //         </Link>
                    //       </Nav>
                    //     </li>
                    //     <li className="nav-item" role="presentation">
                    //       <Nav activeKey={eventKey}>
                    //         <Link href="/" passHref>
                    //           <Nav.Link eventKey="7">
                    //             <span>
                    //               <img src="/assets/images/settings.svg" />
                    //               SETTINGS{' '}
                    //             </span>
                    //           </Nav.Link>
                    //         </Link>
                    //       </Nav>
                    //     </li>
                    //   </>
                    // )
                  }
                </>
              )}

              {/* <li className="nav-item" role="presentation">
                <Link  href="/SalariesPage">
                  <a
                    id="tab1-tab-SALARIES"
                    className={
                      location.pathname === '/SalariesPage'
                        ? 'nav-link active'
                        : 'nav-link'
                    }
                  >
                    <span>
                      <img src="/assets/images/salaries.svg" /> SALARIES{' '}
                    </span>
                  </a>
                </Link>
              </li> */}
            </ul>
          </div>
        </div>
      </section>
    </>
  )
}

export default Header
